import React, {useMemo, useState, useEffect } from 'react';


function GuideHandler(props) {

    var w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    var h = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

    let verticalMiddle = Math.floor(w/2)
    // console.log("middle", verticalMiddle)

    let middle = {
        width: '1px',
        height: '100%',
        backgroundColor: '#8e6dfc',
        position: 'absolute',
        left: verticalMiddle + 'px',
    }

    let visibleGuides;
    let middleAligned = false;
	
	for(let k in props.blockMap) {
        let block = props.blockMap[k];
        // console.log("block left, width", block.left, block.width)
		let blockMiddle = block.left + (block.width / 2);

        // console.log("blockMiddle", blockMiddle, "verticalMid", verticalMiddle)

        if(blockMiddle == verticalMiddle) {
            // console.log("HELLO", blockMiddle, verticalMiddle, middleAligned, props.draggingMode)
            middleAligned = true;
        }
	}

    if(middleAligned && props.draggingMode) {
        // console.log("we made it")
        visibleGuides = (<div style={middle}></div>);
    }


    return (
        <>
          {visibleGuides}
        </>
    );
}

export default GuideHandler;