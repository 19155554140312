// Register.js

import { Link } from "react-router-dom";
import { useAuth } from "./../contexts/AuthContext";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from './../config/firebase';
import Header from './Header';
import { API_URL } from './../constants'; 



export default function Signup() {

const [email, setEmail] = useState("");
const [password, setPassword] = useState("");
const [handle, setHandle] = useState("");

const [confirmPassword, setConfirmPassword] = useState("");  

const navigate = useNavigate();
const { currentUser, register } = useAuth();
const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (currentUser) {
      navigate("/");
    }
  }, [currentUser, navigate]);

async function handleFormSubmit(e) {
	e.preventDefault();

    if (password !== confirmPassword) {
		return alert("Passwords do not match");
    }

    try {
		// console.log("start form submit")
		setLoading(true);
		const val = await checkHandleIsFree();
		// console.log("this is what val is ", val)
		if(val) {
			// console.log("inside the register conditional ?")
			await register(email, password);
			await createUserHandle();
			navigate("/" + handle);
		}
		else {
			alert("Handle already exists. Please pick a different handle");
		}	
    } 
	catch (e) {
		alert("Failed to register", e);
    }

    setLoading(false);
}

async function checkHandleIsFree() {

	const res = await fetch(API_URL + "/checkHandleIsFree/" + handle)
	let resText = await res.text();
	
	if(resText == "handle is free") {
		// console.log("made it inside handle is free block")
		return true
	}
	else {
		// console.log("should be returning false")
		return false
	}
	
		// --> NOT WORKING FOR SOME REASON
		// .then((res) => {
		// 	let response = res.text()
		// 	console.log("response from server:", response)
		// 	if(response == "handle is free") {
		// 		console.log("made it inside handle is free block")
		// 		return true
		// 	}
		// 	else {
		// 		console.log("should be returning false")
		// 		return false
		// 	}
		// })
		// .catch((e) => {
		// 	console.log("error checking handle", e)
		// })
}

let createUserHandle = (userHandle) => {
	if(auth.currentUser.uid) {
		try 
		{
			//params
			const reqBody = {
				userId: auth.currentUser.uid,
				handle: handle,
				auth: auth,
			}
			
			const reqOptions = {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(reqBody)
			}
			const res = fetch(API_URL + "/createHandle", reqOptions);
			// console.log(res);
			return res;
		} 
		catch (e) 
		{
			// console.log(e);
		}
	}
	else {
		// console.log("we don't have current user yet");
	}
	
	
	
	// check for handle, throw error if it already exists
	// get "/checkHandleExists/:handle"
	// if false
		// check to see if user auth is created in auth.currentUser.uid
		// post "/createUser"
		// req includes handle, uid
	// if true
		// throw error (handle already exists)
}


return (
	<div class="accountForm">
		<Header />
		  <h2>
			🏡 Register your garden
		  </h2>
		  <p>Your handle will be used as a subpage, ex: <a href="#">mindgarden.page/[your handle]</a></p>
		<form
			onSubmit={handleFormSubmit}
		>
			  <input
				id="email-address"
				name="email"
				type="email"
				autoComplete="email"
				required
				placeholder="email"
				className="inputCustom"
				onChange={(e) => setEmail(e.target.value)}
			  />
			  <input
				id="handle"
				name="handle"
				type="handle"
				required
				placeholder="handle"
				className="inputCustom"
				onChange={(e) => setHandle(e.target.value)}
			  />
			  <input
				id="password"
				name="password"
				type="password"
				autoComplete="current-password"
				required
				placeholder="password"
				className="inputCustom"
				onChange={(e) => setPassword(e.target.value)}
			  />
			  <input
				id="confirmPassword"
				name="confirmPassword"
				type="password"
				autoComplete="current-password"
				required
				placeholder="confirm password"
				className="inputCustom"
				onChange={(e) => setConfirmPassword(e.target.value)}
			  />
		<button type="submit" className="generalButton">
			Register
		</button>
			<Link to="/login" className="formTinyLink">
				Already have an account? Login
			</Link>
		</form>
	</div>
  );
}