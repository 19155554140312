import React, {Component} from 'react';
import Canvas from './Canvas'
import Draggable from 'react-draggable';
import Block from './Block';
import { Link } from "react-router-dom";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import { auth } from './../config/firebase';
import { API_URL } from './../constants'; 



export default function CreatePage(props) {
    
    const [pageName, setPageName] = useState("");

    const navigate = useNavigate();

        async function handleFormSubmit(e) {
            e.preventDefault();
            let pageName = e.target[0].value
            try {
                // do something to save the page name
                    let pageId = uuidv4();
                    const reqBody = {
                        name: pageName,
                        pageId: pageId,
                        blockMap: {},
                        userId: auth.currentUser.uid,
                        auth: auth
                    }

                    const reqOptions = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify(reqBody)
                    }
                    const res = await fetch(API_URL + "/createPage", reqOptions);
                    let resJson = await res.text();
                    navigate("/" + props.handle + "/" + pageId);
            } 
            catch (e) {
                    // setError("Failed to make page");'
                    // console.log("FAILED TO CREATE PAGE", e);
            }

            // try 
            // {
            //     const reqBody = {
            //         name: pageObject.name,
            //         blockMap: updatedBlockMap,
            //         userId: pageObject.userId
            //     }
                
            //     const reqOptions = {
            //         method: 'POST',
            //         headers: { 'Content-Type': 'application/json' },
            //         body: JSON.stringify(reqBody)
            //     }
            //     const res = await fetch("http://localhost:8080/savePage/" + pageId, reqOptions);
            //     let resJson = await res.text();
            //     console.log(resJson);
            //     setPageObject(reqBody);

            //     return resJson
            // } 
            // catch (e) 
            // {
            //     console.log(e);
            // }

            // setLoading(false);
        }
        return(
            <>
                {/* <h2>create a page</h2> */}
                <p>Create a page: </p>
                <form onSubmit={handleFormSubmit}>
                    <input
                        id="pageName"
                        name="pageName"
                        className="inputCustom"
                        required
                        placeholder="Name of your page"
                        onChange={(e) => setPageName(e.target.value)}
                    />
                    <button
                        type="submit"
                        className="generalButton"
                    >
                        Build
                    </button>
                </form>
            </>
        );
        
        return (
            <Canvas>

            </Canvas>
        )
    }    
