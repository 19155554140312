import logo from './logo.svg';
import Landing from './Components/Landing.js';
import './style/App.css';

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { AuthProvider } from "./contexts/AuthContext"; 
import Signup from "./Components/Signup";
import Canvas from './Components/Canvas'
import Login from "./Components/Login";
import CreatePage from "./Components/CreatePage";
import Page from "./Components/Page";
import Profile from "./Components/Profile";

import WithPrivateRoute from "./Components/WithPrivateRoute";
import ErrorMessage from "./Components/ErrorMessage"; // add this import


function App() {
  return (
	<AuthProvider>
		<Router>
			<ErrorMessage />
			<Routes>
				<Route exact path="/" element={<Landing/>} />
				<Route exact path="/signup" element={<Signup/>} />
				<Route exact path="/login" element={<Login/>} />
				<Route exact path="/canvas" element={<Canvas/>} />
				<Route exact path="/:handle" element={<Profile/>} />
				<Route path="/:handle/:pageId" element={<Page />} />

				<Route
					exact
					path="/createpage"
					element={
						<WithPrivateRoute>
							<CreatePage />
						</WithPrivateRoute>
            		}
          		/>
			</Routes>
		</Router>
	</AuthProvider>
  );
}

export default App;
