import React, {Component, useEffect } from 'react';
import Toolkit from './Toolkit'
import Draggable from 'react-draggable';
import Block from './Block';
import FileDropZone from './FileDropZone';
import GuideHandler from './GuideHandler';
import { getStorage, ref, uploadBytes } from "firebase/storage";
import { auth } from './../config/firebase';
import { API_URL } from './../constants'; 
import axios from "axios";




import { v4 as uuidv4 } from 'uuid';
import './../style/Canvas.scss';

//      /   __/     /   __/     /   __/     /   __/     /   __/     /   _
// __   \__/  \__   \__/  \__   \__/  \__   \__/  \__   \__/  \__   \__/ 
//   \__/  \     \__/  \     \__/  \     \__/  \     \__/  \     \__/  \
// __/     /   __/     /   __/     /   __/     /   __/     /   __/     /
//   \__   \__/  \__   \__/  \__   \__/  \__   \__/  \__   \__/  \__   \_
//      \__/  \     \__/  \     \__/  \     \__/  \     \__/  \     \__/ 
//    __/     /   __/     /   __/     /   __/     /   __/     /   __/    
// __/  \__   \__/  \__   \__/  \__   \__/  \__   \__/  \__   \__/  \__  
//   \     \__/  \     \__/  \     \__/  \     \__/  \     \__/  \     \_
//   /   __/     /   __/     /   __/     /   __/     /   __/     /   __/
//   \__/  \__   \__/  \__   \             _/  \__   \__/  \__   \__/  \_
// __/  \     \__/  \     \__/  canvas.js   \     \__/  \     \__/  \    
//      /   __/     /   __/                 /   __/     /   __/     /   _
// __   \__/  \__   \__/  \__   \__/  \__   \__/  \__   \__/  \__   \__/ 
//   \__/  \     \__/  \     \__/  \     \__/  \     \__/  \     \__/  \
// __/     /   __/     /   __/     /   __/     /   __/     /   __/     /
//   \__   \__/  \__   \__/  \__   \__/  \__   \__/  \__   \__/  \__   \_
//      \__/  \     \__/  \     \__/  \     \__/  \     \__/  \     \__/ 
//    __/     /   __/     /   __/     /   __/     /   __/     /   __/    
// __/  \__   \__/  \__   \__/  \__   \__/  \__   \__/  \__   \__/  \__  
//   \     \__/  \     \__/  \     \__/  \     \__/  \     \__/  \     \_
//   /   __/     /   __/     /   __/     /   __/     /   __/     /   __/
//   \__/  \__   \__/  \__   \__/  \__   \__/  \__   \__/  \__   \__/  \_
// 
// Page-spanning component that right now holds: <Tookit> (the toolbar) and renders all the blocks on a page + manages their state with a "blockMap" object


class Canvas extends Component {

    constructor(props) {
        super(props);

        // figure out browser current width / height
        var width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        var height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
        
        let blockMapData = {};
        
        this.state = {
            activeBlockRef: null,
            browserWidth: width,
            browserHeight: height,
            blockMap: blockMapData,
            guidesVertical: [width/2],
            guidesHorizontal: [],
            multiselector: {},
            nextLeft: 100,
            nextTop: 150,
            focusedBlockKey : null,
            lastCopiedBlockKey: null,
            focusedBlockKeys: [],
            lastCopiedBlockKeys: [],
            textEditMode: false,
            draggingMode: false,
            openFunction: null,
            initialText: "edit me",
            authorLoggedIn: false,
            mobileScrollX: 0, // on mobile, auto scroll to where the top left content is
            mobileScrollY: 0,

        }
        
        // this.setMobileXY(minScrollX, minScrollY);

        // console.log("just went through state", this.state);
    }

    componentDidMount() {
        document.addEventListener("keydown", this.keyboardDetect, false);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.keyboardDetect, false);
    }

    componentDidUpdate(prevProps){
        if(this.props.blockMapData !== prevProps.blockMapData) { // if load happens later
            this.setState({
                blockMap: this.props.blockMapData,
            }, () => {
                this.setMobileXY();
            })
        }
        if(this.props.authorLoggedIn !== prevProps.authorLoggedIn) {
            this.setState({
                authorLoggedIn: this.props.authorLoggedIn,
            })
        }
    }

    setMobileXY = () => {
        let scrollXY = [];

        for (let k in this.state.blockMap) {
            let block = this.state.blockMap[k];

            // get the mobiles scroll position while iterating over the blocks (there might be a better location for this)
            if(scrollXY.length == 0) {
                scrollXY.push(block.left);
                scrollXY.push(block.top);
                // console.log("first time", scrollXY);
            }
            else {
                // console.log("comparing: ", block.left, block.top)
                if(block.left < scrollXY[0] && block.top < scrollXY[1]) {

                    scrollXY[0] = block.left;
                    scrollXY[1] = block.top
                    // console.log("new scrollXY: ", scrollXY);
                }
            }

            // console.log("this block is at: X=" + block.left + ", Y=" + block.top);

            
        }

        // let minScrollX = Math.min(...scrollX);
        // let minScrollY = Math.min(...scrollY);

        // minScrollX -= 50; // give the view some "padding"
        // minScrollY -= 50;

        // console.log("the mins are: X=" + minScrollX + ", Y=" + minScrollY, typeof(scrollX[0]), scrollY);

        if(window.screen.width <= 768) {
            // const scrollingElement = (document.scrollingElement || document.body);
            // console.log("WE'RE SCROLLING")
            // scrollingElement.scrollTop = minScrollY;
            // window.scrollTo(minScrollX, minScrollY);
            document.getElementById("root").scrollTo(scrollXY[0] - 25,scrollXY[1] - 25);
        }

    }

    toggleTextEditMode = (val) => {
        this.setState({
            textEditMode: val,
        })
    }

    getBlock = (uuid) => {
        return this.state.blockMap[uuid]
    }

    updateTextContent = (uuid, textContent) => {
        // let blockCopy = this.getBlock(uuid);
        // blockCopy.textContent = textContent;
        // this.state.blockMap[uuid] = blockCopy;

        this.setState(prevState => ({
            blockMap: {
              ...prevState.blockMap,           // copy all other key-value pairs of blockMap object
            [uuid]: {                         // specific object of blockMap object
                ...prevState.blockMap[uuid],   // copy all block key-value pairs
                textContent: textContent          // update value of specific textContent key
              }
            }
        }))
    }

    updateLeftTopBlockLocation = (uuid, left, top) => {
        this.setState(prevState => ({
            blockMap: {
              ...prevState.blockMap,           // copy all other key-value pairs of blockMap object
            [uuid]: {                         // specific object of blockMap object
                ...prevState.blockMap[uuid],   // copy all block key-value pairs
                left: left,          
                top: top,
              }
            }
        }))
    } 

    updateDataUrl = (uuid, dataUrl) => {
        this.setState(prevState => ({
            blockMap: {
              ...prevState.blockMap,           // copy all other key-value pairs of blockMap object
            [uuid]: {                         // specific object of blockMap object
                ...prevState.blockMap[uuid],   // copy all block key-value pairs
                dataUrl: dataUrl,          
              }
            }
        }))
    } 

    updateRotateAngle = (uuid, rotateAngle) => {
        this.setState(prevState => ({
            blockMap: {
              ...prevState.blockMap,           // copy all other key-value pairs of blockMap object
            [uuid]: {                         // specific object of blockMap object
                ...prevState.blockMap[uuid],   // copy all block key-value pairs
                rotateAngle: rotateAngle
              }
            }
        }))
    }

    updateWidthHeight = (uuid, width, height) => {
        this.setState(prevState => ({
            blockMap: {
              ...prevState.blockMap,           // copy all other key-value pairs of blockMap object
            [uuid]: {                         // specific object of blockMap object
                ...prevState.blockMap[uuid],   // copy all block key-value pairs
                width: width,
                height: height,
              }
            }
        }))
    }

    dragStart = () => {
        this.setState({
            draggingMode: true
        }, () => {
            // console.log("dragging")
        })
    }

    dragEnd = () => {
        // this.setState({
        //     draggingMode: false
        // }, () => {
        // })

        // console.log('HUH', JSON.stringify(this.state.blockMap))
    }


    // ._____. ._____.
    // | ._. | | ._. |
    // | !_| |_|_|_! |
    // !___| |_______!
    // .___|_|_| |___.
    // | ._____| |_. |
    // | !_! | | !_! |
    // !_____! !_____!
    // 
    // all keyboard detect logic 

    keyboardDetect = (e) => {
            // console.log("detect", e)

            // delete
            if((e.keyCode == 8 || e.keyCode === 46) 
                    && this.state.focusedBlockKey != null 
                    && !this.state.textEditMode) {
                delete this.state.blockMap[this.state.focusedBlockKey];
                this.setState({
                    focusedBlockKey: null
                })
            }

            // cmd + c
            else if((e.key == "c" && e.metaKey) && this.state.focusedBlockKey != null && !this.state.textEditMode) {
                this.copyBlock();
                // console.log(this.state.blockMap)
            }

            // cmd + v
            else if((e.key == "v" && e.metaKey) && this.state.focusedBlockKey != null && !this.state.textEditMode) {
                // console.log(this.state.blockMap)
                this.pasteBlock()
            }
    }

    setFocusedBlock = (e) => {
        // console.log("setFocusedBlock called", e);
        let k;
        // let j = 0
        // script to "swim" up the HTML DOM until you find a div tag w/ uuidkey for the block
        let finder = e.target;

        // console.log("did this work", finder.closest(".uuidkey"));
        
        // console.log("finder", finder)
        while(!finder.attributes.uuidkey) {
            if(finder.id == "canvas") {
                this.removeAllFocus();
                return
            }
            // console.log("smth happening", j++, finder)
            finder = finder.parentNode;
            // console.log("smth happening", j, finder.id)
        }

        k = finder.attributes.uuidkey.value;

        this.setState({
            focusedBlockKey: k,
            focusedBlockKeys: [k]
        })        
    }

    removeAllFocus = () => {
        this.setState({
            focusedBlockKey: null,
            focusedBlockKeys: [],
        });
    }


    // called when the user hits cmd + c on their keyboard (from keyboardDetect)
    // sets a copied block key in state and sets up the next paste location to be offset by 50px (this is a little janky, should fix later)
    copyBlock = () => {
        this.setState(prevState => ({
            lastCopiedBlockKey: prevState.focusedBlockKey,
            nextLeft: prevState.blockMap[prevState.focusedBlockKey].left + 25,
            nextTop: prevState.blockMap[prevState.focusedBlockKey].top + 25
        }));
    }


    // called when the user hits cmd + v on their keyboard (from keyboardDetect)
    // creates a copy of the block that was last copied from cmd + c and rerenders (with the new copy pasted (+50, +50)px away from the previous loc).
    pasteBlock = () => {
        
        let id = uuidv4();
        let blockToCopy = this.getBlock(this.state.lastCopiedBlockKey);

        let newBlockData = {
            contentType: blockToCopy.contentType,
            dataUrl: blockToCopy.dataUrl,
            uuidkey: id,
            textContent: blockToCopy.textContent,
            left: this.state.nextLeft,
            top: this.state.nextTop,
            rotateAngle: blockToCopy.rotateAngle,
            width: blockToCopy.width,
            height: blockToCopy.height,
        };

        // copy copy copy
        let prevBlockMap = this.state.blockMap;
        let left = this.state.nextLeft;
        let top = this.state.nextTop;

        // push to list + map
        prevBlockMap[id] = newBlockData;

        // set state
        this.setState({
            blockMap: prevBlockMap,
            nextLeft: left + 25,
            nextTop: top + 25,
            focusedBlockKey: id,
        });
    }

    arrayBufferToString = (buffer) => {

        var bufView = new Uint8Array(buffer);
        var length = bufView.length;
        var result = '';
        var addition = Math.pow(2,16)-1;
    
        for(var i = 0;i<length;i+=addition){
    
            if(i + addition > length){
                addition = length - i;
            }
            result += String.fromCharCode.apply(null, bufView.subarray(i,i+addition));
        }
    
        return result;
    }

    // called from Toolkit.js to add a Block (i.e. a gif, img, text box, etc.!)
    addBlock = (type, dataUrl) => {

        // random id
        let id = uuidv4();

        if(type=="img") {
            // console.log("a file has been uploaded", dataUrl, typeof dataUrl);
            
            let blob;
            var fileReader = new FileReader();
            
            fetch(dataUrl).then(r => {
                    let res = r.blob();
                    // console.log("BLOB ?", res)
                    return res
                })
                .then((rblob) => {
                    // console.log("BLOB !", rblob)

                    // const formData = new FormData();
                    // formData.append('file', rblob);
                    // formData.append('blockId', id);
                    // formData.append('pageId', this.props.pageId);
                    // formData.append('userId', auth.currentUser.uid); 


                    // what chatgpt suggested
                    const formData = new FormData();
                    
                    formData.append('blockId', id);
                    formData.append('pageId', this.props.pageId);
                    formData.append('userId', auth.currentUser.uid);     
                    formData.append('file', rblob, 'file'); // set a file name            

                    // console.log("formData", formData)
                    // let imageUrl = '';
                    try {
                        // const reqBody = {
                        //     // dataUrl: dataUrl,
                        //     formData: formData,
                        //     pageId: this.props.pageid,
                        //     blockId: id,
                        // }
    
                        // // console.log(reqBody);
    
                        // const reqOptions = {
                        //     method: 'POST',
                        //     headers: { 
                        //         // 'Content-Type': 'application/json',
                        //         'Content-Type': 'application/x-www-form-urlencoded'
                        //     },
                        //     // body: JSON.stringify(reqOptions),
                        //     body: reqBody,
                        // }

                        // checking to see formdata values
                        for (var pair of formData.entries()) {
                            // console.log(pair[0]+ ', ' + pair[1]); 
                        }

                        let postURL = API_URL + "/saveImage";

                        axios.post(postURL, formData, {
                            headers: {
                              "Content-Type": "multipart/form-data",
                            }
                        })
                            .then((res) => {
                                // console.log("response", res);
                                this.updateDataUrl(id, res.data) // update the url that the block is using to be the firebase URL
                            })
                            .catch(function (error) {
                                if (error.response) { // get response with a status code not in range 2xx
                                //   console.log(error.response.data);
                                //   console.log(error.response.status);
                                //   console.log(error.response.headers);
                                } else if (error.request) { // no response
                                //   console.log(error.request);
                                  // instance of XMLHttpRequest in the browser
                                  // instance ofhttp.ClientRequest in node.js
                                } else { // Something wrong in setting up the request
                                //   console.log('Error', error.message);
                                }
                                // console.log(error.config);
                              });
                        
                        // axios.post(API_URL + "/saveImage", formData)
                        //     .then((res) => {
                        //         console.log("response", res);
                        //         this.updateDataUrl(id, res.data) // update the url that the block is using to be the firebase URL
                        // })
                        // .catch((e) => {
                        //         console.log("error", e)
                        // })
                        
                        // const res = fetch("http://localhost:8080/saveImage", reqOptions)
                            // .then((res) => {
                            //     console.log("response", res)
                            // })
                    } 
                    catch(error) {
                        console.error(error);
                    }
                    return;

                    const reqBody = {
                        formData: rblob,
                        dataUrl: dataUrl,
                        // arrayBuffer: convertToString,
                        blockKey: id,
                        pageId: this.props.pageId,
                        userId: auth.currentUser.uid,
                    }

                    

                    return 
                    let hm = rblob.arrayBuffer();

                    return hm
                    // fileReader.readAsArrayBuffer(rblob);
                    // console.log("array buffer?", fileReader)
                })
                .then((r) => {

                    return
                    console.log("what happened", r);
                    let convertToString = this.arrayBufferToString(r);
                    console.log("ARB as string", convertToString);

                    const reqBody = {
                        dataUrl: dataUrl,
                        arrayBuffer: convertToString,
                        blockKey: id,
                        pageId: this.props.pageId,
                        userId: auth.currentUser.uid,
                    }

                    console.log(reqBody);

                    const reqOptions = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify(reqBody)
                    }
                    const res = fetch(API_URL + "/saveImage", reqOptions)
                        .then(() => {})
        
                    console.log("RESPONSE FROM SAVING:", res)

                    return r;
                })
            
            

            

            // const reqOptions = {
            //     method: 'POST',
            //     headers: { 'Content-Type': 'application/json' },
            //     body: JSON.stringify(reqBody)
            // }
            // const res = fetch("http://localhost:8080/saveImage", reqOptions)
            //     .then(() => {})

            // console.log("RESPONSE FROM SAVING:", res)
        }

        let newBlockData = {
            contentType: type,
            dataUrl: dataUrl,
            uuidkey: id,
            textContent: null,
            left: this.state.nextLeft,
            top: this.state.nextTop,
            rotateAngle: 0,
            width: null,
            height: null,
        }
        
        // copy blockmap + left + top data from state
        let prevBlockMap = this.state.blockMap;
        let left = this.state.nextLeft; // can probably just move this to state I think
        let top = this.state.nextTop;

        // push to list + map
        prevBlockMap[id] = newBlockData;

        // set state
        this.setState({
            blockMap: prevBlockMap,
            nextLeft: left + 25,
            nextTop: top + 25,
            focusedBlockKey: id,
            focusedBlockKeys: [id]
        });
    }

    uploadFiles = (files) => {
        if(files[0]) {
            this.addBlock("img", files[0].preview);
        }
        
    }

    sendOpenFunction = (open) => {
        if(!this.state.openFunction) {
            this.setState({
                openFunction: open,
            })
        }
    }

    handleClick = (e) => {
        // console.log("click event", e)

        clearTimeout(this.state.clickTimer);
        if(e.target.classList.contains("resizable-handler") 
            || (e.target.nodeName == "I" && e.target.parentNode.classList.contains("rotate"))
            || !e.target.attributes) {
            return	
        }
        
        this.setFocusedBlock(e);

        // double click logic for text blocks
        if(this.state.contentType == "text") {
            if(e.detail == 1 && !this.state.editable) {
                this.setState({
                    clickTimer: setTimeout(() => {}, 500),
                    editable: false,
                })
            }
            else if(e.detail == 2) {
                this.setState({
                    editable: true,
                })
            }
        }
    }

    handleMouseDown = (e) => {
        this.handleClick(e);
        // // console.log("mouse down event", e)
        // console.log("state", this.state)
        

        // let multiselector = {
        //     left: e.clientX,
        //     top: e.clientY,
        //     initX: e.clientX,
        //     initY: e.clientY,
        //     width: "0px",
        //     height: "0px"
        // }


        // // script to "swim" up the HTML DOM
        // let finder = e.target;
        // while(!finder.attributes.uuidkey) {
        //     if(finder.id == "canvas") {
        //         this.setState({
        //             multiselector
        //         })
        //         return
        //     }
        //     // console.log("smth happening", j++, finder)
        //     finder = finder.parentNode;
        //     // console.log("smth happening", j, finder.id)
        // }
        
    }

    handleOnDrag = (e) => {
        // console.log("on drag", e)
    }

    handleMouseUp = (e) => {
        // console.log("mouse up", e)
        // get corners of selector 
        // let uuidList
        // loop through block content, check corners and see if it fits in selector
            // if so, grab the UUID of the block
        // setFocusedBlock on all UUIDs (new function?)

        // if(!this.state.multiselector) {
        //     return;
        // }

        // let left = this.state.multiselector.left;
        // let right = this.state.multiselector.left + this.state.multiselector.width;
        // let top = this.state.multiselector.top;
        // let bottom = this.state.multiselector.top + this.state.multiselector.height;

        // let focusedList = [];

        // console.log(left, right, top, bottom)
        // for (let k in this.state.blockMap) {
        //     let block = this.state.blockMap[k];

        //     let blockLeft = block.left;
        //     let blockRight = block.left + block.width;
        //     let blockTop = block.top;
        //     let blockBottom = block.top + block.height;


        //     let inWindowX = false;
        //     let inWindowY = false

        //     if(blockLeft >= left && blockLeft <= right) {
        //         inWindowX = true
        //     }
        //     if(blockRight >= left && blockRight <= right) {
        //         inWindowX = true
        //     }
        //     if(blockTop <= bottom && blockTop >= top) {
        //         inWindowY = true
        //     }
        //     if(blockBottom <= bottom && blockBottom >= top) {
        //         inWindowY = true
        //     }

        //     if(inWindowX && inWindowY) {
        //         focusedList.push(block.uuidkey)
        //     }
        // }

        // console.log(focusedList);



        // this.setState({
        //     multiselector: null,
        //     focusedBlockKeys: focusedList
        // }, () => {
        //     console.log(this.state)
        // })

        // let width = Math.abs(e.clientX - this.state.multiselector.left);
        // let height = Math.abs(e.clientY - this.state.multiselector.top);

        // let multiselector = {
        //     top: this.state.multiselector.top,
        //     left: this.state.multiselector.left,
        //     width: width,
        //     height: height
        // }
        
        // this.setState({
        //     multiselector
        // })
        // delete lelemt 
    }

    handleMouseMove = (e) => {
        // console.log("mouse move event", e)

        if(!this.state.multiselector) {
            return;
        }

        let width = e.clientX - this.state.multiselector.initX;
        let height = e.clientY - this.state.multiselector.initY;
        let top = this.state.multiselector.top;
        let left = this.state.multiselector.left
        let initX = this.state.multiselector.initX;
        let initY = this.state.multiselector.initY

        if(width < 0) {
            left = initX + width;
        }
        else {
            left = initX;
        }

        if(height < 0) {
            top = initY + height;
        }
        else {
            top = initY;
        }

        let multiselector = {
            top: top,
            left: left,
            width: Math.abs(width),
            height: Math.abs(height),
            initX: initX,
            initY: initY,
        }
        
        // this.setState({
        //     multiselector
        // })
        // adjust element
    }
   

    render() {

        let renderBlocks = [];

        let multiselector;
        let multiselectorStyle;
        if (this.state.multiselector) {
            multiselectorStyle = {
                left: this.state.multiselector.left,
                top: this.state.multiselector.top,
                width: this.state.multiselector.width,
                height: this.state.multiselector.height,
            }
    
            multiselector =  <div 
                                id="multiselector"
                                style={multiselectorStyle}
                            >
                                
                            </div>;
        }

        // let scrollX = [];
        // let scrollY = [];

        for (let k in this.state.blockMap) {
            let block = this.state.blockMap[k];

            // get the mobiles scroll position while iterating over the blocks (there might be a better location for this)
            // scrollX.push(block.left);
            // scrollY.push(block.top);

            // console.log("this block is at: X=" + block.left + ", Y=" + block.top);

            renderBlocks.push(<Block     
                                    // prob should batch some of this stuff up
                                    contentType={block.contentType}
                                    dataUrl={block.dataUrl}
                                    width={block.width}
                                    height={block.height}
                                    updateWidthHeight={this.updateWidthHeight}
                                    rotateAngle={block.rotateAngle}
                                    updateRotateAngle={this.updateRotateAngle}
                                    left={block.left}
                                    top={block.top}
                                    updateLeftTopBlockLocation={this.updateLeftTopBlockLocation}
                                    setFocusedBlock={this.setFocusedBlock}
                                    removeAllFocus={this.removeAllFocus}
                                    focusedBlockKey={this.state.focusedBlockKey}
                                    focusedBlockKeys={this.state.focusedBlockKeys}
                                    key={block.uuidkey}
                                    uuidkey={block.uuidkey}
                                    toggleTextEditMode={this.toggleTextEditMode}
                                    textContent={block.textContent}
                                    updateTextContent={this.updateTextContent}
                                    initialText={this.state.initialText}
                                    onDragStart={this.handleOnDrag}
                                    dragStart={this.dragStart}
                                    dragEnd={this.dragEnd}
                                    authorLoggedIn={this.state.authorLoggedIn}
                                />)
        }

        // let minScrollX = Math.min(...scrollX);
        // let minScrollY = Math.min(...scrollY);

        // console.log("the mins are: X=" + minScrollX + ", Y=" + minScrollY, typeof(scrollX[0]), scrollY);
        
        // this.setMobileXY(minScrollX, minScrollY);

        return (
            <>
            <div
                onClick={this.handleClick}
                onMouseDown={this.handleMouseDown}
                onMouseUp={this.handleMouseUp}
                onMouseMove={this.handleMouseMove}
                id="canvas"
            >
                {/* <Toolkit 
                    addBlock={this.addBlock}
                    receiveOpenFunction={this.state.openFunction}
                /> */}
                {/* {multiselector} */}
                {renderBlocks}
                <FileDropZone 
                    uploadFiles={this.uploadFiles}
                    sendOpenFunction={this.sendOpenFunction}
                    focusedBlockKey={this.state.focusedBlockKey}
                />
                {/* <GuideHandler 
                    guidesVertical={this.state.guidesVertical}
                    guidesHorizontal={this.state.guidesHorizontal}
                    draggingMode={this.state.draggingMode}
                    blockMap={this.state.blockMap}
                /> */}
            </div>
            <Toolkit 
                    addBlock={this.addBlock}
                    receiveOpenFunction={this.state.openFunction}
                    savePage={this.props.savePage}
                    blockMap={this.state.blockMap}
                    authorLoggedIn={this.state.authorLoggedIn}
            />
            </>
        );
    }    

}

export default Canvas;




// "<!DOCTYPE html>
// <html lang="en">
// <head>
// <meta charset="utf-8">
// <title>Error</title>
// </head>
// <body>
// <pre>TypeError: Cannot read properties of undefined (reading &#39;mimetype&#39;)<br> &nbsp; &nbsp;at /Users/adhivd/Documents/projects/mbackend/functions/lib/index.js:87:42<br> &nbsp; &nbsp;at Layer.handle [as handle_request] (/Users/adhivd/Documents/projects/mbackend/functions/node_modules/express/lib/router/layer.js:95:5)<br> &nbsp; &nbsp;at next (/Users/adhivd/Documents/projects/mbackend/functions/node_modules/express/lib/router/route.js:144:13)<br> &nbsp; &nbsp;at Immediate._onImmediate (/Users/adhivd/Documents/projects/mbackend/functions/node_modules/multer/lib/make-middleware.js:53:37)<br> &nbsp; &nbsp;at process.processImmediate (node:internal/timers:473:21)</pre>
// </body>
// </html>"