import React, {Component, Fragment} from 'react';
import GiphyTool from './GiphyTool';
import plus from '../content/img/plus.png';
import text from '../content/img/text.png';
import img from '../content/img/img.png';
import gif from '../content/img/gif.png'

import './../style/Canvas.scss';


class Toolkit extends Component {

    constructor(props) {
        super(props);

        this.state = {
            giphyModalOpen: false,
            successVisible: false,
        }
    }

    uploadImage = () => {
        if(this.props.receiveOpenFunction) {
            this.props.receiveOpenFunction()
        }
    }
    
    addText = () => {
        this.props.addBlock('text');
    }

    toggleGiphyModal = () => {
        this.setState({
            giphyModalOpen: !this.state.giphyModalOpen,
        });
    }

    addGif = (dataUrl) => {
        this.props.addBlock('gif', dataUrl);
        this.toggleGiphyModal();
    }

    savePage = () => {
        this.props.savePage(this.props.blockMap)
            .then((res) => {
                // console.log("RES RSER ES", res);

                if(res == "success") {
                    this.setState({
                        successVisible: true
                    })
        
                    setTimeout(() => {
                        this.setState({
                            successVisible: false
                        })
                    }, 1500);
                }

            })
    }

    render() {
        // var intervalId = window.setInterval(this.savePage, 15000);

        let giphyModal;

        if(this.state.giphyModalOpen) {
            giphyModal = <GiphyTool 
                addGif={this.addGif}
            />
        }

        let success;
        if(this.state.successVisible) {
            success = (<p className="success">✅ Saved</p>);
        }

        let editingKit;
        if(this.props.authorLoggedIn) {
            editingKit = (
                        <div className="toolkit">
                             {/* <div className="action add" onClick={this.uploadImage}>
                                <img src={img} />
                            </div>  */}
                            <div className="action add" onClick={this.addText}>
                                <img src={text} />
                            </div>
                            <div className="action add" onClick={this.toggleGiphyModal}>
                                <img src={gif} />
                            </div>
                            <div className="action add" onClick={this.savePage}>
                                <p>save</p>
                            </div>
                        </div>
                    );
        }

        

        return (
            <div className="canvasMenu">
                {editingKit}
                {success}
                {giphyModal}
            </div>
        );
    } 

}

export default Toolkit;
