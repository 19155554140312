import React, {Component} from 'react';
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import Logout from './Logout';
import Header from './Header';

import { auth } from '../config/firebase';
import { useNavigate } from "react-router-dom";
import Profile from './Profile';
import './../style/general.scss';
import { useAuth } from "../contexts/AuthContext";
import { API_URL } from './../constants'; 
import howtouse from './../content/gifs/howtouse.gif'


function Landing() { // "mindgarden.me/" --> login, signup page. if signed in, redirect to their profile
    
    const [userObject, setUserObject] = useState();
    const { setUserInfo, userInfo, currentUser } = useAuth();
    // let checkStoredLocal = localStorage.getItem("userInfo")
    
    const navigate = useNavigate();

// CACHING LOGIC THAT I DONT UNDERSTAND, TRYING TO DO IT WITHOUT IT 1/7/23
    // if(currentUser) { // user that's already logged in trying to go to "/"
    //     if(checkStoredLocal === null) { // no stored local userInfo (but there should be)
    //         // setUserInfo(checkStoredLocal)
    //         // navigate("/" + userInfo.handle) // go to the user profile

    //     }
    //     else if(checkStoredLocal && checkStoredLocal.handle) { // we've already stored the login info locally
    //         setUserInfo(checkStoredLocal)
    //         navigate("/" + userInfo.handle) // go to the user profile
    //     }
    // }

    if(currentUser) { // user that's already logged in trying to go to "/"
        // get their handle
        // navigate to (/handle)
        // profile component will take it from there
    }

    

    useEffect(() => {
        // FETCH USER INFO AFTER LOGGING IN
        // console.log("this is running");
        const fetchUser = async () => {
            if(auth.currentUser) {
                try 
                {
                    const reqBody = {
                        auth: auth
                    }

                    const reqOptions = {
                        method: 'GET',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify(reqBody)
                    }

                    const res = await fetch(API_URL + "/getUser/" + auth.currentUser.uid);
                    let resJson = await res.json();
                    // console.log("response from resJson useEffect", resJson)
                    navigate("/" + resJson.handle, { profileObject: resJson })

                    // once we have the data, navigate and send data with it


                    // return resJson;
                    // setUserObject(resJson);
                    // setUserInfo(resJson); // establishes userInfo in AuthContext (i.e. handle + pages)
                } 
                catch (e) 
                {
                    // console.log(e);
                }
            }
        };
        fetchUser();
      }, [auth.currentUser]);

    // useEffect(() => {
    //     if(userInfo) {
    //         // localStorage.setItem("userInfo", JSON.stringify(userInfo))
    //         navigate("/" + userInfo.handle) // janky, but basically redirect to *your* handle after logging in
    //     }
        
    // }, [userInfo])
    
    let signup;
    let login;
    let logout;
    let profile;
      
    if (auth.currentUser) {
        // console.log("User " + auth.currentUser.email + " is logged in", auth.currentUser.uid);
    } 
    else {
        // console.log("User is logged out");
        login = (<Link to="/login" className="generalButton">login</Link>);
        signup = (<Link to="/signuphidden" className="generalButton">sign up</Link>);
    }

        return (
            <div className='landing'>
                
                <Header />
                <div className="accountLinks">
                    {/* { signup } */}
                    { login }
                </div>
                <div className="landingDescription">
                    <h4>what is this?</h4>
                    <p>a very simple drag + drop web page builder. I didn't like any of the blogging templates out there so I wanted to make my own. A little garden on the internet to spill my thoughts on, in a weird edgy way (hence "mindgarden")</p>
                    <h4>can I try using it?</h4>
                    <p><a href="https://twitter.com/adhivdhar" target="_blank">dm me</a> and I'll let you in</p>
                    {/* <h4>how do i use it?</h4>
                    <p>1. sign up</p>
                    <p>2. enter the name of your page → hit "Build"</p>
                    <p>3. your page has been made! the link is shareable. click the text + gif blocks to create and <b>save</b> often </p> */}
                    <p><img src={howtouse} style={{ width: '500px', borderRadius: '6px'}}/></p>
                </div>
                <div className="signature">
                    <p>built with ❤️ by <a href="https://adhiv.com/" target="_blank">adhiv</a></p>
                </div>
            </div>
        );
}

export default Landing;
