import React, {Component} from 'react';
import Canvas from './Canvas'
import Draggable from 'react-draggable';
import Block from './Block';
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { auth } from './../config/firebase';
import { useParams } from "react-router-dom";
import { useAuth } from "./../contexts/AuthContext";
import Logout from './Logout'


function Header(props) {
    let logout

    if(auth.currentUser) {
        logout = (<Logout />);
        // console.log("User " + auth.currentUser.email + " is logged in", auth.currentUser.uid);
    }

    return (
        <div className="header">
            <Link to="/"><h1>🌱 mindgarden </h1> <h4> (beta)</h4></Link>
            <p><span className="hideOnMobile">• </span>drag and drop microblogging</p>
            {logout}
        </div>
    );
}

export default Header;
