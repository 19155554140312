import { useAuth } from "./../contexts/AuthContext";

export default function ErrorMessage() {
  const { error, setError } = useAuth();

  return (
    error && (
      <div>
                <div 
                    onClick={() => setError("")}
                >
                    X
                </div>
              <h3>
                Error: {error}
              </h3>
      </div>
    )
  );
}