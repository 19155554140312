import React, {Component} from 'react';
import Canvas from './Canvas'
import Draggable from 'react-draggable';
import Block from './Block';
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import CreatePage from './CreatePage'
import { useParams } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { auth } from '../config/firebase';
import { API_URL } from './../constants'; 




function Page(props) {

    let { handle, pageId } = useParams(); 

    const [pageObject, setPageObject] = useState({});
    const { currentUser, userInfo } = useAuth();




    useEffect(() => {

        const fetchPage = async () => {
            try 
            {
                const reqBody = {
                    auth: auth
                }

                const reqOptions = {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(reqBody)
                }
                
                const res = await fetch(API_URL + "/getPage/" + pageId);
                let resJson = await res.json();
                // console.log("page info", resJson);
                setPageObject(resJson);
            } 
            catch (e) 
            {
                // console.log(e);
            }
        };
        // console.log("FETCHED PAGE")
        fetchPage();
      },[]);

    useEffect(() => {
        if(pageObject.name) {
            document.title = pageObject.name + ' • @' + handle + ' • mindgarden'
        }
    }, [pageObject]) 

    let savePage = async (updatedBlockMap) => {                
            try 
            {
                const reqBody = {
                    name: pageObject.name,
                    blockMap: updatedBlockMap,
                    userId: pageObject.userId,
                    auth: auth
                }

                // console.log("what im sending")
                
                const reqOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(reqBody)
                }
                const res = await fetch(API_URL + "/savePage/" + pageId, reqOptions);
                let resJson = await res.text();
                // console.log("save page response, ", resJson);
                setPageObject(reqBody);

                return resJson
            } 
            catch (e) 
            {
                // console.log(e);
            }
    }

    let authorLoggedIn = false;
    if(currentUser && currentUser.uid == pageObject.userId) {
        // console.log("AUTHOR IS INDEED LOGGED IN", currentUser.uid, pageObject.userId)
        authorLoggedIn = true;
    }

    // console.log("check author logged in", authorLoggedIn)
    
    let blockMapData;

    if(pageObject.blockMap) {
        blockMapData = pageObject.blockMap;
    }

    return (
        <>
            {/* header */}
            <Canvas 
                blockMapData={blockMapData}
                savePage={savePage}
                authorLoggedIn={authorLoggedIn}
                pageId={pageId}
            />
        </>
    );
}

export default Page;
