
// const API_URL = "http://localhost:8080"; // dev
// const API_URL = "http://127.0.0.1:5001/moodboards-b8265/us-central1/app"; 
const API_URL = process.env.REACT_APP_API_URL; // prod

export { API_URL };


// "<!DOCTYPE html>
// <html lang="en">
// <head>
// <meta charset="utf-8">
// <title>Error</title>
// </head>
// <body>
// <pre>TypeError: Cannot read properties of undefined (reading &#39;mimetype&#39;)<br> &nbsp; &nbsp;at /Users/adhivd/Documents/projects/mbackend/functions/lib/index.js:87:42<br> &nbsp; &nbsp;at Layer.handle [as handle_request] (/Users/adhivd/Documents/projects/mbackend/functions/node_modules/express/lib/router/layer.js:95:5)<br> &nbsp; &nbsp;at next (/Users/adhivd/Documents/projects/mbackend/functions/node_modules/express/lib/router/route.js:144:13)<br> &nbsp; &nbsp;at Immediate._onImmediate (/Users/adhivd/Documents/projects/mbackend/functions/node_modules/multer/lib/make-middleware.js:53:37)<br> &nbsp; &nbsp;at process.processImmediate (node:internal/timers:473:21)</pre>
// </body>
// </html>
// "