import { Fragment, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./../contexts/AuthContext";

export default function Logout() {
  const cancelButtonRef = useRef(null);
  const navigate = useNavigate();

  const { logout, setError } = useAuth();

  async function handleLogout() {
    try {
      setError("");
      await logout();
      // localStorage.setItem("userInfo", null)
      navigate("/login");
    } catch {
      setError("Failed to logout");
    }
  }

  return (
                <button
                  type="button"
                  className="generalButton"
                  onClick={handleLogout}
                >
                  Logout
                </button>
  );
}