
import { Link } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./../contexts/AuthContext";
import Header from './Header';



export default function Login() {

const [email, setEmail] = useState("");
const [password, setPassword] = useState("");
const [loading, setLoading] = useState(false);
const navigate = useNavigate();
const { currentUser, login, setError, userInfo } = useAuth();

if(currentUser) {
	navigate("/")
}

	
async function handleFormSubmit(e) {
	e.preventDefault();

    try {
      setError("");
      setLoading(true);
      await login(email, password);
      navigate("/");
    } catch (e) {
      setError("Failed to login");
    }

    setLoading(false);
}

return (
	<div className="accountForm">
		<Header />
		  <h2>
			Login to your account
		  </h2>
		<form onSubmit={handleFormSubmit}>
			  <input
				id="email-address"
				name="email"
				type="email"
				autoComplete="email"
				required
				placeholder="Email address"
				className="inputCustom"
				onChange={(e) => setEmail(e.target.value)}
			  />
			  <input
				id="password"
				name="password"
				type="password"
				autoComplete="current-password"
				required
				placeholder="Password"
				className="inputCustom"
				onChange={(e) => setPassword(e.target.value)}

			  />
			<button
			  type="submit"
			  className="generalButton"
			>
			  Login
			</button>
			  {/* <Link
				to="/signup"
				className="formTinyLink"
			  >
				Don't have an account? Sign up
			  </Link> */}
		</form>
	</div>
  );
}