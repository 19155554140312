import React, {Component} from 'react';
import Canvas from './Canvas'
import Draggable from 'react-draggable';
import Block from './Block';
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import CreatePage from './CreatePage'
import { auth } from './../config/firebase';
import { useParams, useLocation } from "react-router-dom";
import { useAuth } from "./../contexts/AuthContext";
import Header from './Header';
import { API_URL } from './../constants'; 


function Profile(props) {

    let navigationProps = useLocation();
    let profileObjectInit = {}
    if(navigationProps.profileObject) {
        profileObjectInit = navigationProps.profileObject
    }

    const [profileObject, setProfileObject] = useState(profileObjectInit); // used to be {}
    const [loadingPages, setLoadingPages] = useState(true);
    const [ownerLoggedIn, setOwnerLoggedIn] = useState(false); // flag to see if the owner of the current profile page is logged in

    let { handle } = useParams(); // get handle from profile routing (midngarden.com/[:handle])
    const { currentUser, userInfo, setUserInfo } = useAuth();

    // console.log("here's what we know about currentUser: ", currentUser)

    // send changes up to Canvas on text change
	// useEffect(() => {
    //     console.log("owner logged in useEffect is run", ownerLoggedIn)
	// }, [ownerLoggedIn]);

    useEffect(() => {
        // console.log("profileobject useEffect run", profileObject);
        
        // if(currentUser && profileObject.handle == handle) {
        //     setOwnerLoggedIn(true);
        // }

        if(profileObject && Object.keys(profileObject).length === 0) {
            // setLoaadingPages(true)
            fetchUser();
        }
        else {
            setLoadingPages(false)
        }
	}, [profileObject]);

    useEffect(() => {
        if(profileObject.handle) {
            document.title = '@' + handle + ' • mindgarden'
        }
    }, [profileObject])

    
    const formatISODate = (isoDate) => {
        let date = new Date(isoDate);
    
        // Check if isoDate does not include a time (only date part)
        if (!isoDate.includes('T')) {
            // Add 1 day to the date to simulate UTC to EST conversion
            date = new Date(date.getTime() + (24 * 60 * 60 * 1000));
        }
    
        const monthNames = ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"];
        const month = monthNames[date.getMonth()];
        const day = date.getDate();
        const year = date.getFullYear().toString().slice(-2); // Get last two digits of the year
        
        return `   ${month} ${day} ${year}`;
    };
      
    //   // Example usage:
    //   const isoDate = "2023-06-30T14:48:00.000Z";
    //   console.log(formatISODate(isoDate)); // Output: "jun 30 23"


    // check to see if profile is the user's own profile
    // if it's not, then fetch the handle + profile

    const fetchUser = async () => {
        // console.log("fetched User called")

        
        
        try 
        {
            const reqBody = {
                auth: auth
            }

            const reqOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(reqBody)
            }
            
            const userIdRes = await fetch(API_URL + "/getUserId/" + handle);
            let userId = await userIdRes.text();
            // console.log("user Id from /getUserId", userId)

            if(currentUser && userId == currentUser.uid) {
                setOwnerLoggedIn(true);
            }

            const res = await fetch(API_URL + "/getUser/" + userId);
            let resJson = await res.json();
            
            // console.log("/getUser response", resJson);

            setProfileObject(resJson);
        } 
        catch (e) 
        {
            // console.log(e);
        }

        // setLoaadingPages(false)
    };

    // if profileobject is empty
    //      run fetchUser


    // fetchUser();

    

    // useEffect(() => {
    //     if(userInfo) {
    //         console.log("USE EFFECT PROFILE", userInfo)
    //         if(userInfo.handle == handle) { // if the user is on their own profile, use the existing user Info object (containing handle + page info)
    //             setProfileObject(userInfo)
    //             setOwnerLoggedIn(true);
    //             console.log("aaaaaaa")
    //         }
    //         else {
    //             fetchUser(); // otherwise, fetch the profile from API call 
    //             console.log("bbbbbbb")
    //         }
    //     }
    // }, [userInfo])

    // if(currentUser && !userInfo) { // CACHING LOGIC I DONT UNDERSTAND RIGHT NOW
    //     setUserInfo(JSON.parse(localStorage.getItem("userInfo")));
    // }

    let pageList = [];
    let loading;

    if(loadingPages) // while loading the req 
    {
        loading = "loading...";
    }
    else if(profileObject && !profileObject.pages) { // no pages
        loading = handle + " currently has no pages"

        if(ownerLoggedIn) {
            loading += " – create a page using the form above 🎨"
        }
    }
    else {
        loading = "";
    }

    if (profileObject && profileObject.pages) {
        let datedPages = [];
        let undatedPages = [];
    
        // Categorize pages into dated and undated arrays
        Object.keys(profileObject.pages).forEach(key => {
            const page = profileObject.pages[key];
            if (typeof page === 'string') {
                undatedPages.push({
                    id: key,
                    name: page
                });
            } else if (typeof page === 'object' && page.name) {
                if(page.draft){
                    return
                }

                if (page.createdAt) {
                    datedPages.push({
                        id: key,
                        name: page.name,
                        createdAt: page.createdAt
                    });
                } else {
                    undatedPages.push({
                        id: key,
                        name: page.name
                    });
                }
            }
        });
    
        // Sort dated pages by createdAt in descending order (most recent first)
        datedPages.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    
        // Sort undated pages alphabetically by name
        undatedPages.sort((a, b) => a.name.localeCompare(b.name));
    
        // Combine sorted arrays
        const sortedPages = [...datedPages, ...undatedPages];
    
        sortedPages.forEach(page => {
            let pageId = page.id;
            let pageLink = `/${handle}/${pageId}`;
            let pageName = page.name;
            let date = '';
    
            if (page.createdAt) {
                let dateString = formatISODate(page.createdAt);
                date = <span className="pageDate">{dateString}</span>;
            }
    
            pageList.push(
                <p key={pageId} className="pageFormat">
                    <Link to={pageLink} className="pageLink">
                        {pageName}
                    </Link>
                    {date}
                </p>
            );
        });
    }
    

    // if (profileObject && profileObject.pages) {
    //     // Convert object keys to an array of pages
    //     const pagesArray = Object.keys(profileObject.pages).map(key => ({
    //         id: key,
    //         ...profileObject.pages[key]
    //     }));
    
    //     // Sort pages by createdAt in descending order (most recent first)
    //     const sortedPages = pagesArray.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    
    //     sortedPages.forEach(page => {
    //         let pageId = page.id;
    //         let pageLink = `/${handle}/${pageId}`;
    //         let pageName = '';
    //         let date = '';
    
    //         if (typeof page === 'string') {
    //             pageName = page;
    //         } else if (typeof page === 'object' && page.name) {
    //             pageName = page.name;
    //             let dateString = formatISODate(page.createdAt);
    //             date = <span className="pageDate">{dateString}</span>;
    //         }
    
    //         pageList.push(
    //             <p key={pageId} className="pageFormat">
    //                 <Link to={pageLink} className="pageLink">
    //                     {pageName}
    //                 </Link>
    //                 {date}
    //             </p>
    //         );
    //     });
    // }
    

    // if(profileObject) {
    //     console.log("check here: ", profileObject)
    //     for (let k in profileObject.pages) {
            
    //         // console.log(k, props.pages);
    //         let pageId = k;
    //         let pageLink = "/" + handle + "/" + pageId
    //         let date = ""
    //         let pageName = ""

    //         // legacy -> added a new "pageMeta" on the backend that saves when the page was created weeeeeeeee so now you have to check whether you're loading the old data format (straight string of the page name) or the new pageMeta (object containing page name and date created)

    //         let checkObjectType = profileObject.pages[k]
    //         console.log("checkObjectType", checkObjectType);
    //         if (typeof checkObjectType === 'string') {
    //             pageName = profileObject.pages[k]
    //             date = "";
    //         } 
    //         else if (typeof checkObjectType === 'object' && checkObjectType !== null && !Array.isArray(checkObjectType)) {
    //             pageName = profileObject.pages[k].name
    //             let dateString = formatISODate(profileObject.pages[k].createdAt)
    //             date = (<span className="pageDate">{dateString}</span>);
    //         }

    //         pageList.push(
    //             <p className="pageFormat">
    //                         <Link 
    //                             to={pageLink}
    //                             className="pageLink"
    //                         >
    //                             {pageName}
    //                         </Link>
    //                         {date}
    //             </p>
    //     )};
    //     // pageList = props.pages.map((p) => {
    //     //     console.log(p)
    //     //     // return <Link to="#">pag</Link>;
    //     // })
    // }

    let createPage;

    if(ownerLoggedIn) {
        // console.log("owner is logged in check")
        createPage = (<CreatePage 
                        handle={handle}
                        />);
    }
    else {
        // console.log("owner not logged in, profile object:", profileObject)
    }
   
        return (
            <div className="profile">
                <Header />
                {createPage}
                <h1>@{handle}</h1>
                {/* <h2>pages</h2> */}
                {loading}
                { pageList }
            </div>
        );
        
        return (
            <Canvas>

            </Canvas>
        )
}

export default Profile;
